import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import GeneralLayout from '../components/GeneralLayout';
import CategoryMenu from '../components/CategoryMenu';
import ThemeChip from '../components/Works/ThemeChip';

export default function Theme({ pageContext }) {
  const listQuery = useStaticQuery(graphql`
    query {
      allSanityProject {
        nodes {
          category {
            title
          }
          id
        }
      }
      allSanityTheme(
        sort: {order: ASC, fields: title}
        filter: { slug: { current: { ne: "null" } } }
      ) {
        nodes {
          id
          slug {
            current
          }
          title
        }
      }
    }
  `);

  const {
    title, slug, description, mainImage, otherImages, id
  } = pageContext;
  const activeCategory = null;
  const setActiveCategory = null;
  const themesList = listQuery.allSanityTheme.nodes.map((node) => ({
    id: node.id,
    slug: node.slug,
    title: node.title
  }));

  useEffect(() => {
    document.getElementsByTagName('html')[0].style.overflowY = 'auto';
    document.getElementsByTagName('body')[0].style.overflowY = 'auto';
    document.getElementById('___gatsby').style.overflowY = 'auto';
    document.getElementById('gatsby-focus-wrapper').style.overflowY = 'auto';
  }, []);

  return (
    <GeneralLayout>
      <div className="info">
        <div className="result">
          <div className="result-content theme-page">
            <div className="result-chips">
              <div className="result-theme-chips">
                <ThemeChip
                  key={id}
                  id={id}
                  slug={slug}
                  themesList={themesList}
                  place="result"
                />
              </div>
            </div>
            <div className="result-header">
              <h3 className="works">{title}</h3>
            </div>
          </div>
        </div>
        <div className="theme-content-container">
          <div className="text-content">
            {description
              ? description.map((paragraph, i) => {
                if (i === 0) {
                  return (
                    <p key={paragraph.children[0]._key} className="desc-text first-desc-text">
                      {paragraph.children[0].text}
                    </p>
                  );
                }
                return (
                  <p key={paragraph.children[0]._key} className="desc-text">
                    {paragraph.children[0].text}
                  </p>
                );
              })
              : ''}
          </div>
          <div className="image-content">
            <GatsbyImage className="project-image theme-page" image={mainImage} alt={title} />
            {otherImages ? otherImages.map((otherImage) => (
              <GatsbyImage className="project-image theme-page" image={otherImage} alt={title} />
            )) : null}
          </div>
        </div>
      </div>
      <CategoryMenu
        categoryType="Works"
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        listQuery={listQuery}
      />
    </GeneralLayout>
  );
}
